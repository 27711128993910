import gql from 'graphql-tag'

export const getBlogQuery = `
query Blog($slug: String!) {
  blog(where: { slug: $slug }) {
    title
    slug
    content {
      document
    }
    image {
      url
    }
    seoTitle
    seoDescription
    seoFollow
    seoKeywords
    seoImage {
      url
    }
    published
    publishedAt
  }
}
`
export default gql(getBlogQuery)
